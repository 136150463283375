import { Cell } from "./cell.js";

const GRID_SIZE = 4;
const CELLS_COUNT = GRID_SIZE * GRID_SIZE;

export class Grid {
  constructor(gridElement) {
    this.cells = [];
    this.gridElement = gridElement;
    this.createGrid();

    this.cellsGroupedByColumn = this.groupCellsByColumn();
    this.cellsGroupedByReversedColumn = this.cellsGroupedByColumn.map(
      (column) => [...column].reverse()
    );
    this.cellsGroupedByRow = this.groupCellsByRow();
    this.cellsGroupedByReversedRow = this.cellsGroupedByRow.map((row) =>
      [...row].reverse()
    );
  }

  createGrid() {
    for (let i = 0; i < GRID_SIZE; i++) {
      this.cells[i] = [];
      for (let j = 0; j < GRID_SIZE; j++) {
        this.cells[i][j] = new Cell(this.gridElement, j, i);
      }
    }
  }

  getRandomEmptyCell() {
    const emptyCells = this.cells.flat().filter((cell) => cell.isEmpty());
    const randomIndex = Math.floor(Math.random() * emptyCells.length);
    return emptyCells[randomIndex];
  }

  groupCellsByColumn() {
    const grouped = [];
    for (let i = 0; i < GRID_SIZE; i++) {
      grouped[i] = [];
      for (let j = 0; j < GRID_SIZE; j++) {
        grouped[i][j] = this.cells[j][i];
      }
    }
    return grouped;
  }

  groupCellsByRow() {
    return this.cells;
  }

  cleanCells() {
    this.cells.flat().forEach((cell) => cell.reset());
  }
}
