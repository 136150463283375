export function detectSwipe(element, callback) {
  let touchStartX = 0;
  let touchStartY = 0;
  let touchEndX = 0;
  let touchEndY = 0;

  element.addEventListener("touchstart", function(event) {
    touchStartX = event.changedTouches[0].screenX;
    touchStartY = event.changedTouches[0].screenY;
    event.preventDefault(); 
  }, false);

  element.addEventListener("touchmove", function(event) {
    event.preventDefault(); 
  }, false);

  element.addEventListener("touchend", function(event) {
    touchEndX = event.changedTouches[0].screenX;
    touchEndY = event.changedTouches[0].screenY;
    event.preventDefault(); 
    handleSwipe();
  }, false);

  function handleSwipe() {
    const deltaX = touchEndX - touchStartX;
    const deltaY = touchEndY - touchStartY;

    if (Math.abs(deltaX) > Math.abs(deltaY)) {
      if (deltaX > 0) {
        callback("right");
      } else {
        callback("left");
      }
    } else {
      if (deltaY > 0) {
        callback("down");
      } else {
        callback("up");
      }
    }
  }
}
