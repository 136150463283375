import { io } from "socket.io-client";
import slugify from "slugify";

import { Grid } from "./grid.js";
import { Tile } from "./tile.js";
import { detectSwipe } from "./swipe.js";

const socket = io(process.env.BACKEND_URL);

const playerScores = document.querySelector("#player-scores");

const time = document.querySelector(".time");

const showBoardBtn = document.querySelector(".btn-board");
const scoreBoard = document.querySelector(".scoreboard");

async function getTop5HighScores() {
  try {
    const res = await fetch(`${process.env.BACKEND_URL}/api/users`);
    const users = await res.json();
    playerScores.innerHTML = users.data
      .sort((a, b) => b.highScore - a.highScore)
      .slice(0, 5)
      .map((user) => `<tr><td>${user.name}</td><td>${user.highScore}</td></tr>`)
      .join("");
  } catch (error) {
    console.error("Error fetching high scores:", error);
  }
}

let room = null;
let isMultiplayer = false;
let gameState = {};

const name = prompt("What is your name?");
if (!name) {
  alert("Name is required to play the game!");
  throw new Error("Name is required");
}

if (window.location.pathname.includes("multiplayer.html")) {
  isMultiplayer = true;
  joinRoom();
  time.innerText = "00:00";
}

if (!isMultiplayer) {
  showBoardBtn.addEventListener("click", () => {
    scoreBoard.classList.toggle("showBoard");
  });
}

function joinRoom() {
  room = prompt("Enter room name:");

  if (room) {
    socket.emit("joinRoom", room, name, handleRoomJoin);
  }
}

function handleRoomJoin(response) {
  if (response === "success") {
    console.log(`Joined room: ${room}`);
  } else {
    console.error(response);
    room = prompt("Error joining room. Please enter another room name:");
    if (room) {
      socket.emit("joinRoom", room, name, handleRoomJoin);
    }
  }
}

socket.on("error", (error) => {
  room = prompt("Please enter another room name (This room is full):");
  if (room) {
    socket.emit("joinRoom", room, name, handleRoomJoin);
  }
});

if (isMultiplayer) {
  document
    .querySelectorAll(".player-1-name")
    .forEach((el) => (el.textContent = name));
} else {
  document.querySelector(".current_score").textContent = `Score: 0`;
  document.querySelector(".best_score").textContent = `Best: 0`;
}

if (name !== localStorage.getItem("playerName")) {
  localStorage.clear();
  localStorage.setItem("playerName", name);
}

let gameBoards = document.querySelectorAll(".game_cont");
const newGameButton = document.getElementById("new_game");
let score = parseInt(localStorage.getItem("currentScore"), 10) || 0;
let bestScore = parseInt(localStorage.getItem("bestScore"), 10) || 0;

if (isMultiplayer) {
  document.querySelector(".player-1-score").textContent = score;
} else {
  document.querySelector(".current_score").textContent = `Score: ${score}`;
}

async function handleSendScore(name, score) {
  const userName = slugify(name);
  const url = `${process.env.BACKEND_URL}/api/users/${userName}`;

  try {
    const response = await fetch(url);
    const options = {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ highScore: score }),
    };

    if (response.ok) {
      const { data } = await response.json();
      if (score > data.highScore) {
        await fetch(url, options);
      }
    } else if (response.status === 404) {
      await fetch(`${process.env.BACKEND_URL}/api/users/`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ name, highScore: score }),
      });
    }

    if (!isMultiplayer) {
      getTop5HighScores();
    }
  } catch (error) {
    console.error("Error sending score:", error);
  }
}

let grid;
let secondPlayerGrid;
try {
  gameBoards.forEach((board, index) => {
    if (board) {
      if (index === 0) {
        grid = new Grid(board);
      } else if (isMultiplayer && index === 1) {
        secondPlayerGrid = new Grid(board);
      }
    } else {
      console.error("Invalid gameBoard element:", board);
    }
  });
} catch (error) {
  console.error("Error initializing grid:", error);
}

const gameBoard = gameBoards[0]; // Define the gameBoard variable here

function setupInputOnce() {
  window.addEventListener("keydown", handleInput, { once: true });
}

function initializeGame() {
  const gameState = localStorage.getItem("gameState");
  if (gameState) {
    loadGameState();
  } else {
    startNewGame();
  }
  setupInputOnce();
  detectSwipe(gameBoard, handleSwipe); // Add swipe detection
}

function startNewGame() {
  grid.cleanCells();
  score = 0;
  updateScoreDisplay();
  grid.getRandomEmptyCell().linkTile(new Tile(gameBoard));
  grid.getRandomEmptyCell().linkTile(new Tile(gameBoard));
  if (!isMultiplayer) saveGameState();
  if (isMultiplayer) sendGameState();
}

async function handleInput(event) {
  let moved = false;

  switch (event.key) {
    case "ArrowUp":
      if (canMoveUp()) {
        await moveUp();
        moved = true;
      }
      break;
    case "ArrowDown":
      if (canMoveDown()) {
        await moveDown();
        moved = true;
      }
      break;
    case "ArrowLeft":
      if (canMoveLeft()) {
        await moveLeft();
        moved = true;
      }
      break;
    case "ArrowRight":
      if (canMoveRight()) {
        await moveRight();
        moved = true;
      }
      break;
    default:
      setupInputOnce();
      return;
  }

  if (!moved) {
    setupInputOnce();
    return;
  }

  const newTile = new Tile(gameBoard);
  grid.getRandomEmptyCell().linkTile(newTile);

  if (!canMoveUp() && !canMoveDown() && !canMoveLeft() && !canMoveRight()) {
    await newTile.waitForAnimationEnd();
    if (isMultiplayer) {
      announceWinner();
    } else {
      alert("Try again!");
      handleSendScore(name, score);
    }
    return;
  }

  if (!isMultiplayer) saveGameState();
  if (isMultiplayer) sendGameState();
  setupInputOnce();
}

async function handleSwipe(direction) {
  let moved = false;

  switch (direction) {
    case "up":
      if (canMoveUp()) {
        await moveUp();
        moved = true;
      }
      break;
    case "down":
      if (canMoveDown()) {
        await moveDown();
        moved = true;
      }
      break;
    case "left":
      if (canMoveLeft()) {
        await moveLeft();
        moved = true;
      }
      break;
    case "right":
      if (canMoveRight()) {
        await moveRight();
        moved = true;
      }
      break;
    default:
      return;
  }

  if (!moved) {
    return;
  }

  const newTile = new Tile(gameBoard);
  grid.getRandomEmptyCell().linkTile(newTile);

  if (!canMoveUp() && !canMoveDown() && !canMoveLeft() && !canMoveRight()) {
    await newTile.waitForAnimationEnd();
    alert("Try again!");
    handleSendScore(name, score);
    if (score > bestScore) {
      bestScore = score;
      bestScoreDisplay.innerText = `Best: ${bestScore}`;
      localStorage.setItem("bestScore", bestScore);
    }
    localStorage.setItem("currentScore", score);
    saveGameState();
    return;
  }

  saveGameState();
}

async function moveUp() {
  await slideTiles(grid.cellsGroupedByColumn);
}

async function moveDown() {
  await slideTiles(grid.cellsGroupedByReversedColumn);
}

async function moveLeft() {
  await slideTiles(grid.cellsGroupedByRow);
}

async function moveRight() {
  await slideTiles(grid.cellsGroupedByReversedRow);
}

async function slideTiles(groupedCells) {
  const promises = [];
  groupedCells.forEach((group) => slideTilesInGroup(group, promises));
  await Promise.all(promises);
  grid.cells.flat().forEach((cell) => {
    if (cell.hasTileForMerge()) {
      const mergedValue = cell.mergeTiles();
      addToScore(mergedValue);
    }
  });
}

function slideTilesInGroup(group, promises) {
  for (let i = 1; i < group.length; i++) {
    if (group[i].isEmpty()) {
      continue;
    }

    const cellWithTile = group[i];
    let targetCell;
    let j = i - 1;
    while (j >= 0 && group[j].canAccept(cellWithTile.linkedTile)) {
      targetCell = group[j];
      j--;
    }

    if (!targetCell) {
      continue;
    }

    promises.push(cellWithTile.linkedTile.waitForTransitionEnd());

    if (targetCell.isEmpty()) {
      targetCell.linkTile(cellWithTile.linkedTile);
    } else {
      targetCell.linkTileForMerge(cellWithTile.linkedTile);
    }

    cellWithTile.unlinkTile();
  }
}

function canMoveUp() {
  return canMove(grid.cellsGroupedByColumn);
}

function canMoveDown() {
  return canMove(grid.cellsGroupedByReversedColumn);
}

function canMoveLeft() {
  return canMove(grid.cellsGroupedByRow);
}

function canMoveRight() {
  return canMove(grid.cellsGroupedByReversedRow);
}

function canMove(groupedCells) {
  return groupedCells.some((group) => canMoveInGroup(group));
}

function canMoveInGroup(group) {
  return group.some((cell, index) => {
    if (index === 0) {
      return false;
    }
    if (cell.isEmpty()) {
      return false;
    }
    const targetCell = group[index - 1];
    return targetCell.canAccept(cell.linkedTile);
  });
}

if (!isMultiplayer) {
  newGameButton.addEventListener("click", () => {
    startNewGame();
  });
}
function addToScore(points) {
  score += points;
  updateScoreDisplay();
}

function updateScoreDisplay() {
  if (isMultiplayer) {
    document.querySelector(".player-1-score").textContent = score;
  } else {
    document.querySelector(".current_score").textContent = `Score: ${score}`;
  }
  localStorage.setItem("currentScore", score);
}

function saveGameState() {
  const gameState = {
    grid: grid.cells.map((row) =>
      row.map((cell) => (cell.linkedTile ? cell.linkedTile.value : null))
    ),
  };
  try {
    localStorage.setItem("gameState", JSON.stringify(gameState));
  } catch (error) {
    console.error("Error saving game state:", error);
  }
}

function loadGameState() {
  try {
    const gameState = JSON.parse(localStorage.getItem("gameState"));

    if (gameState) {
      grid.cleanCells();
      gameState.grid.forEach((row, rowIndex) => {
        row.forEach((cellValue, colIndex) => {
          if (cellValue !== null) {
            const cell = grid.cells[rowIndex][colIndex];
            const tile = new Tile(gameBoard);
            tile.setValue(cellValue);
            cell.linkTile(tile);
          }
        });
      });
    }
  } catch (error) {
    console.error("Error loading game state:", error);
  }
}

function sendGameState() {
  gameState = {
    grid: grid.cells.map((row) =>
      row.map((cell) => (cell.linkedTile ? cell.linkedTile.value : null))
    ),
    score,
    name: localStorage.getItem("playerName"),
    room,
  };

  socket.emit("gameStateUpdate", gameState);
}

socket.on("gameStateUpdate", (newGameState) => {
  console.log("Received game state update:", newGameState);
  gameState = newGameState;

  if (isMultiplayer) {
    document
      .querySelectorAll(".player-2-name")
      .forEach((el) => (el.textContent = gameState.name));
    document.querySelector(".player-2-score").textContent = gameState.score;
    if (secondPlayerGrid) {
      secondPlayerGrid.cleanCells();
      gameState.grid.forEach((row, rowIndex) => {
        row.forEach((cellValue, colIndex) => {
          if (cellValue !== null) {
            const cell = secondPlayerGrid.cells[rowIndex][colIndex];
            const tile = new Tile(gameBoards[1]);
            tile.setValue(cellValue);
            cell.linkTile(tile);
          }
        });
      });
    }
  }
});

// Prevent scrolling the page with arrow keys
window.addEventListener(
  "keydown",
  function (e) {
    if (
      ["Space", "ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].indexOf(
        e.code
      ) > -1
    ) {
      e.preventDefault();
    }
  },
  false
);

initializeGame();
getTop5HighScores();

function announceWinner() {
  const player1Score = parseInt(
    document.querySelector(".player-1-score").textContent
  );
  const player2Score = parseInt(
    document.querySelector(".player-2-score").textContent
  );

  let winner;
  if (player1Score > player2Score) {
    winner = name;
  } else if (player2Score > player1Score) {
    winner = gameState.name;
  } else {
    winner = "Tie";
  }

  socket.emit("gameOver", { winner, room });
  alert(`Game over! Winner: ${winner}`);
  location.reload();
}

function formatTime(timeLeft) {
  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;
  return `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;
}

socket.on("gameOver", ({ winner }) => {
  alert(`Game over! Winner: ${winner}`);
  location.reload();
});

socket.on("timer", (timeLeft) => {
  console.log(`Time left: ${timeLeft} seconds`);
  time.innerHTML = formatTime(timeLeft);
});
