export class Cell {
  constructor(gridElement, x, y) {
    const cell = document.createElement("div");
    cell.classList.add("cell");
    gridElement.append(cell);
    this.x = x;
    this.y = y;
  }

  linkTile(tile) {
    tile.setXY(this.x, this.y);
    this.linkedTile = tile;
  }

  unlinkTile() {
    this.linkedTile = null;
  }

  isEmpty() {
    return !this.linkedTile;
  }

  linkTileForMerge(tile) {
    tile.setXY(this.x, this.y);
    this.linkedTileForMerge = tile;
  }

  unlinkTileForMerge() {
    this.linkedTileForMerge = null;
  }

  hasTileForMerge() {
    return !!this.linkedTileForMerge;
  }

  canAccept(newTile) {
    return (
      this.isEmpty() ||
      (!this.hasTileForMerge() && this.linkedTile.value === newTile.value)
    );
  }

  mergeTiles() {
    this.linkedTile.setValue(
      this.linkedTile.value + this.linkedTileForMerge.value
    );
    const mergedValue = this.linkedTile.value;
    this.linkedTileForMerge.removeFromDOM();
    this.unlinkTileForMerge();
    return mergedValue;
  }

  reset() {
    if (this.linkedTile) {
      this.linkedTile.removeFromDOM();
    }
    if (this.linkedTileForMerge) {
      this.linkedTileForMerge.removeFromDOM();
    }
    this.unlinkTile();
    this.unlinkTileForMerge();
  }
}
