export class Tile {
  constructor(gridElement) {
    if (!gridElement || typeof gridElement.append !== "function") {
      throw new Error("gridElement is not a valid DOM element");
    }

    this.tileElement = document.createElement("div");
    this.tileElement.classList.add("tile");
    this.setValue(Math.random() > 0.5 ? 2 : 4);
    gridElement.append(this.tileElement);
  }

  setValue(value) {
    this.value = value;
    this.tileElement.textContent = value;

    switch (value) {
      case 2:
        this.tileElement.style.backgroundColor = "#eee3da";
        this.tileElement.style.color = "#333";
        break;
      case 4:
        this.tileElement.style.backgroundColor = "#eddfc8";
        this.tileElement.style.color = "#333";
        break;
      case 8:
        this.tileElement.style.backgroundColor = "#f1b178";
        this.tileElement.style.color = "#f9f6f1";
        break;
      case 16:
        this.tileElement.style.backgroundColor = "#f59562";
        this.tileElement.style.color = "#f9f6f1";
        break;
      case 32:
        this.tileElement.style.backgroundColor = "#f57d5f";
        this.tileElement.style.color = "#f9f6f1";
        break;
      case 64:
        this.tileElement.style.backgroundColor = "#f65e3b";
        this.tileElement.style.color = "#f9f6f1";
        break;
      case 128:
        this.tileElement.style.backgroundColor = "#edcf72";
        this.tileElement.style.color = "#f9f6f1";
        break;
      case 256:
        this.tileElement.style.backgroundColor = "#edcc62";
        this.tileElement.style.color = "#f9f6f1";
        break;
      case 512:
        this.tileElement.style.backgroundColor = "#edc751";
        this.tileElement.style.color = "#f9f6f1";
        break;
      case 1024:
        this.tileElement.style.backgroundColor = "#ecc43f";
        this.tileElement.style.color = "#f9f6f1";
        break;
      case 2048:
        this.tileElement.style.backgroundColor = "#3c3a33";
        this.tileElement.style.color = "#f9f6f1";
        break;
      default:
        break;
    }
  }

  setXY(x, y) {
    this.x = x;
    this.y = y;
    this.tileElement.style.setProperty("--x", x);
    this.tileElement.style.setProperty("--y", y);
  }

  animateMerge() {
    this.tileElement.classList.add('merge')

  }

  removeFromDOM() {
    this.tileElement.remove();
  }

  waitForTransitionEnd() {
    return new Promise((resolve) => {
      this.tileElement.addEventListener("transitionend", resolve, {
        once: true,
      });
    });
  }

  waitForAnimationEnd() {
    return new Promise((resolve) => {
      this.tileElement.addEventListener("animationend", resolve, {
        once: true,
      });
    });
  }
}
